import "./style.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mantine/core";
import Team1 from "assets/img/team/jay_hao_rounded.png";
import Team2 from "assets/img/team/mikalai_harbacheuski_rounded.png";
import Team3 from "assets/img/team/julia_harbacheuskaya_rounded.png";
import Team4 from "assets/img/team/evgeniy_butyaev_rounded.png";
import Team5 from "assets/img/team/evgeniy_azarevich_rounded.png";
import Team6 from "assets/img/team/sergey_kochnev_rounded.png";
import Team7 from "assets/img/team/grigoriy_kleiman_rounded.png";

function MyTeam() {
  const { t } = useTranslation();

  return (
    <div className="myTeam">
      <Container className="myTeam-container">
        <h3>{t("myTeam")}</h3>
        <div className="single-top">
          <img src={Team2} alt="mobile" />
          <span>Mikalai</span>
          <span>Harbacheuski</span>
        </div>
        <div className="group">
          <div className="single">
            <img src={Team1} alt="mobile" />
            <span>Jay</span>
            <span>Hao</span>
          </div>
          <div className="single">
            <img src={Team3} alt="mobile" />
            <span>Julia</span>
            <span>Harbacheuskaya</span>
          </div>
          <div className="single">
            <img src={Team4} alt="mobile" />
            <span>Evgeniy</span>
            <span>Butyaev</span>
          </div>
          <div className="single">
            <img src={Team5} alt="mobile" />
            <span>Evgeniy</span>
            <span>Azarevich</span>
          </div>
          <div className="single">
            <img src={Team6} alt="mobile" />
            <span>Sergey</span>
            <span>Kochnev</span>
          </div>
          <div className="single">
            <img src={Team7} alt="mobile" />
            <span>Gregory</span>
            <span>Lomonov</span>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MyTeam;
